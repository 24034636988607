import React from 'react';
import { motion } from 'framer-motion';
import Brand1 from '../img/steven/brand1.png';
import Content from '../img/steven/content.png';
import Brand3 from '../img/steven/brand3.png';
import Brand4 from '../img/steven/brand4.png';
import Brand5 from '../img/steven/brand5.png';
import Brand6 from '../img/steven/brand6.png';
import Brand7 from '../img/steven/brand7.png';
import Brand8 from '../img/steven/brand8.png';
import Brand9 from '../img/steven/brand9.png';
import Brand10 from '../img/steven/brand10.png';

const Steven = () => {
  return (
    <section>
      <div className='section bg-[#8785a2] relative z-10 w-[100%] overflow-auto'>
        <div className='w-[100%]'>
          <div className='relative'>
            <motion.div 
              animate={{ y: 30}}
              transition={{ duration: 2 }}
              className='mx-[24px] pt-[200px] md:mx-[60px]'>
              <div>
                <div className='flex align-baseline'>
                  <div className='w-[100%] text-left m-0 text-[#FFE2E2]'>
                    <h2 className='uppercase text-3xl lg:text-5xl md:text-4xl'>steven-dev</h2>
                    <div className='border-[1px] w-full bg-white mt-3 mb-5'></div>
                  </div>
                </div>
              </div>
            </motion.div>
            <motion.div 
              animate={{ y: 30 }}
              transition={{ duration: 2 }}
              className='lg:flex justify-between mt-3  md:flex justify-between mt-3'>
              <div className='w-[100%] pl-[24px] pr-[14px] lg:w-[50%] md:pl-[60px] md:pr-[60px] md:w-[50%]'>
                <table>
                  <tbody>
                    <tr>
                      <td className='align-top'>
                        <h6 className='uppercase  text-xl font-bold'>Category</h6>
                      </td>
                      <td className='align-top'>
                        <ul className='mx-12 p-0 list-none'>
                          <li className='text-xl'>Branding</li>
                          <li className='text-xl'>UX/UI Design</li>
                          <li className='text-xl'>Web Development</li>
                        </ul>-
                      </td>
                    </tr>
                    <tr>
                      <td className='align-top'>
                        <h6 className='uppercase text-xl font-bold py-2'>Year</h6>
                      </td>
                      <td className='align-top'>
                        <p className='transform-none text-xl mx-12 py-2'>2023</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='w-[100%] px-[24px] lg:w-[50%] lg:mr-[60px] md:mr-[60px] md:w-[50%]'>
                <p className='text-xl m-0 pb-4'>Steven is the nickname I use on social medias. The goal of this project was to create my own identity. First, the identity needed to be defined in brand guidelines and then had to be adapted for digital medias (website, wallpapers, social medias) and physical formats (business card, letter paper and resume).</p>
              </div>
            </motion.div>
            <div className='mx-[24px] lg:px-[240px] text-xl md:px-[60px]'>
              <h6 className='uppercase text-2xl mt-[60px] text-center font-semibold'>Brand guidelines</h6>
              <div className='my-[24px] flex'>
                <img src={Brand1} alt='' />
              </div>
              <div className='my-[24px] flex'>
                <img src={Content} className='w-[100%] h-[100%] object-cover' alt='' />
              </div>
              <div className='my-[24px] flex'>
                <img src={Brand3} className='w-[100%] h-[100%] object-cover' alt='' />
              </div>
              <div className='my-[24px] flex'>
                <img src={Brand4} alt='' />
              </div>
              <div className='my-[24px] flex'>
                <img src={Brand5} alt='' />
              </div>
              <div className='my-[24px] flex'>
                <img src={Brand6} alt='' />
              </div>
              <div className='my-[24px] flex'>
                <img src={Brand7} alt='' />
              </div>
              <div className='my-[24px] flex'>
                <img src={Brand8} alt='' />
              </div>
              <div className='my-[24px] flex'>
                <img src={Brand9} alt='' />
              </div>
              <div className='my-[24px] flex'>
                <img src={Brand10} alt='' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Steven;