import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const Portfolio = () => {
  return (
    <section>
      <div className='section bg-[#8785a2] relative z-10 w-[100%]'>
        <div className='w-[100%] h-[100%] lg:w-[50%]'>
          <div className='flex flex-row h-screen pt-[240px]'>
            <div className='relative h-[100%] md:hidden lg:hidden'></div>
              <motion.div 
                animate={{ y: 30}}
                transition={{ duration: 2}}
                className='flex flex-col w-[100%] h-[100%] pl-[24px] pr-[24px]'>
                <div className='opacity-100'>
                  <div>
                    <div className='flex items-baseline'>
                      <h1
                        className='w-[100%] text-left uppercase font-bold text-3xl mb-2 lg:text-6xl md:text-5xl '>work</h1>
                      <h5 className='tracking-widest lowercase text-2xl'>1</h5>
                    </div> 
                  </div>
                </div>
                <div className='border-[1px] w-full bg-white mb-5'></div>
                <div className='opacity-100'>
                  <div>
                    <div className='flex items-center justify-between'>
                      <Link to='/steven' className='text-[#F6F6F6] hover:text-secondary transition'><h4 className='w-[100%] text-xl tracking-widest mb-2'>steven</h4></Link>
                      <h4 className='text-xl tracking-widest mb-2'>Branding</h4>
                    </div>
                  </div>
                </div>
                <div className='border-[1px] w-full bg-white mb-5'></div>
              </motion.div>
            </div>
          </div>
        </div>
    </section>
  )
};

export default Portfolio;

