import React, {useState} from 'react';
import { IoMdClose } from 'react-icons/io';
import { CgMenuRight } from 'react-icons/cg';
import {Link} from 'react-router-dom';
import { motion } from 'framer-motion'; 

const menuVariants = {
  hidden: {
    x:'100%'
  },
  show: {
    x:0,
    transition: {
      ease: [0.6,0.01,-0.05,0.9],
    },
  },
};


const MobileNav = () => {
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <nav className='text-primary xl:hidden'>
      <div onClick={() => setOpenMenu(true)}className='text-3xl cursor-pointer'>
        <CgMenuRight />
      </div>
      <motion.div 
      variants={menuVariants} 
      initial='hidden' 
      animate={openMenu ? 'show' : ''}
      className='bg-[#6E7C7C] shadow-2xl w-full absolute top-0 right-0 max-w-xs h-screen z-20'>
        <div 
        onClick={() => setOpenMenu(false)}
        className='text-4xl absolute z-30 left-4 top-14 text-primary cursor-pointer'>
          <IoMdClose />
        </div>
        <ul className='h-full flex flex-col justify-center items-center gap-y-8 font-bold text-3xl'>
          <li>
            <Link to={'/'} className='text-[#F6F6F6] hover:text-secondary transition'>Home</Link>
          </li>
          <li>
            <Link to={'/about'} className='text-[#F6F6F6] hover:text-secondary transition'>About</Link>
          </li>
          <li>
            <Link to={'/portfolio'} className='text-[#F6F6F6] hover:text-secondary transition'>Projects</Link>
          </li>
          <li>
            <Link to={'/contact'} className='text-[#F6F6F6] hover:text-secondary transition'>Contact</Link>
          </li>
        </ul>
      </motion.div>
    </nav> 
  );
};

export default MobileNav;