import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';


const Home = () => {
  return ( 
    <section
      className='section bg-[#8785A2]'>
      <div className='container mx-auto h-full relative'>
        <div className='flex  ml-0'>
          <div className='w-full  pt-36 ml-0 lg:pt-40 lg:pb-0 lg:w-auto z-10 lg:absolute flex flex-col  items-center lg:items-start'>
            <motion.h1
              animate={{ y: 30}}
              transition={{ duration: 2}}
              className='text-[50px] lg:text-[70px] font-bold capitalize leading-[120%] tracking-[-0.05em] pt-20'>web developer <br /> & graphic designer</motion.h1>
            <motion.p
              animate={{ y: 20}}
              transition={{ duration: 2}}
              className='text-[26px] lg:text-[36px]  mb-6 lg:mb-12 md:mb-7 mt-4'>Buenos Aires, ARG</motion.p>
            <Link 
              animate={{ y: 6}}
              transition= {{ duration: 2}}
              to={'/contact'} className='btn mb-[30px] text-[22px] text-[#F6F6F6] bg-[#6E7C7C] font-bold  hover:text-secondary transition'>hire me</Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;