import React from 'react';

import {
  ImFacebook,
  ImInstagram,
} from 'react-icons/im';

const Socials = () => {
  return (
    <div className='hidden xl:flex ml-24'>
      <ul className='flex gap-x-4'>
        <li>
          <a href='http://www.facebook.com/stevendev6/' target='_blank'>
            <ImFacebook />
          </a>
        </li>
        <li>
          <a href='http://www.instagram.com/?wtsid=rdr_0AeTwwv1pj4yQXlz5m/' target='_blank'>
            <ImInstagram />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Socials;