import React from 'react';
import AboutmeImg from '../img/about/aboutme.png';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { transition1 } from '../transitions';

const About = () => {
  return  ( 
    <section className='section bg-[#8785A2]'>
      <div className='container mx-auto h-full relative '>
        <div className=' flex flex-col lg:flex-row h-full items-center justify-center gap-x-24 text-center lg:text-left lg:pt-16'>
          <motion.div
            initial={{ scale: 0}}
            animate={{ scale: 1}}
            exit={{ scale: 0}}
            transition={transition1}
            className='flex-1 max-h-96 lg:max-h-max order-2 lg:order-none overflow-hidden'>
            <motion.img
              whileHover={{ scale: 1.1}}
              transition={transition1}
              src={AboutmeImg} alt='' />
          </motion.div>
          <div className='flex-1 pt-36 pb-14 lg:pt-0 lg:w-auto z-10 flex flex-col justify-center items-center lg:items-start'>
            <motion.h1
              animate={{ y: 20}}
              transition={{ duration: 2}}
              className='font-semibold text-[46px]  lg:text-[66px] mb-2'>About me</motion.h1>
            <motion.p
                animate={{ y: 8}}
                transition={{ duration: 2}} 
                className='mb-8 max-w-sm'>I'm Steven <b>front-end developer</b> and <b>graphic designer</b> based in Argentina, with a passion for design and technology. I currently work as a freelancer. I love minimalist design, volleyball and practice calisthenics.</motion.p>
            <Link
              animate={{ y: 4}}
              transition={{ duration: 2}}
             to={'/portfolio'} className='btn mb-[30px]  font-semibold text-[#F6F6F6] bg-[#6E7C7C] hover:text-secondary transition'>View my work</Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;