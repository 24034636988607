import React from 'react';
import { AnimatePresence } from 'framer-motion';

import Home from '../pages/Home';
import About from '../pages/About';
import Portfolio from '../pages/Portfolio';
import Contact from '../pages/Contact';


import {Routes, Route, useLocation } from 'react-router-dom';
import Steven from '../pages/Steven';

const AnimRoutes = () => {
    const location = useLocation();
    return (
        <AnimatePresence initial={true} mode='wait'>
            <Routes key={location.pathname} Location={location}>
                <Route path='/' element={<Home />} />
                <Route path='/about' element={<About />} />
                <Route path='/portfolio' element={<Portfolio />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/steven' element={<Steven />} />
            </Routes>
        </AnimatePresence>
    );
};

export default AnimRoutes;