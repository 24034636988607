import React, { useRef } from 'react';
import ContactImg from '../img/contact/contactme.png';
import { motion } from 'framer-motion';
import { transition1 } from '../transitions';
import emailjs from "@emailjs/browser";
import toast, { Toaster } from 'react-hot-toast';

const Contact = () => {
  const form = useRef();

  // Sending Email
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ewx2spm",
        "template_m3foil6",
        form.current,
        "zJTJ4Sbbb_bYJyhe6"
      )
      .then (
        (result) => {
          console.log(result.text);
          form.current.reset(); 
          toast.success("Email send Successfully");
        },
        (error) => {
          console.log(error.text);
          toast.error(error.text);
        }
      );
      
  };
  
  return ( 
    <section className='section bg-[#8785A2]'>
      <Toaster />
      <div className='container mx-auto h-full'>
        <div className='flex flex-col lg:flex-row h-full items-center justify-start pt-30 gap-x-8 text-center lg:text-left'>
          <div className='hidden lg:flex bg-[#8785A2] absolute bottom-0 left-0 right-0 top-72 -z-10'></div>
          <motion.div 
            animate={{ y: 10}}
            transition={{ duration: 2}}
            className='lg:flex-1 lg:pt-40 px-4'>
            <h1 className='font-semibold text-[46px]  lg:text-[66px]'>Contact me</h1>
            <p className='mb-12'>i would love to get suggestions from you.</p>
            <form ref={form} onSubmit={sendEmail} className='flex flex-col gap-y-4'>
              <div className='flex gap-x-10'>
                <input className='outline-none border-b border-b-[#6E7C7C] h-[60px] bg-transparent w-full pl-3 placeholder:text-[#F6F6F6]' type='text' placeholder='Your name' name="from_name" required/>
                <input className='outline-none border-b border-b-[#6E7C7C] h-[60px] bg-transparent w-full pl-3 placeholder:text-[#F6F6F6]' type='email' placeholder='Your email address' name="user_email" required />
              </div>
              <input className='outline-none border-b border-b-[#6E7C7C] h-[60px] bg-transparent w-full pl-3 placeholder:text-[#F6F6F6]' type='text' placeholder='Your message' name="message" h-44 />
              <button className='btn mb-[30px] font-semibold text-[#F6F6F6] bg-[#6E7C7C] hover:text-secondary transition'>Send it</button>
            </form>
          </motion.div>
          <motion.div
            initial={{ scale: 0}}
            animate={{ scale: 1}}
            exit={{ scale: 0}}
            transition={transition1}
            className='lg:flex-1'>
            <motion.img
              whileHover={{ scale: 1.1}}
              transition={transition1}
              src={ContactImg} alt='Imagen de jannoon028 en Freepik' />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Contact;